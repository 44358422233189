import { render, staticRenderFns } from "./PraticheCaricate.vue?vue&type=template&id=03993305&"
import script from "./PraticheCaricate.vue?vue&type=script&lang=js&"
export * from "./PraticheCaricate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QSpinnerHourglass from 'quasar/src/components/spinner/QSpinnerHourglass.js';
import QTable from 'quasar/src/components/table/QTable.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QIcon,QPopupProxy,QDate,QBtn,QSelect,QSpinnerHourglass,QTable});qInstall(component, 'directives', {ClosePopup});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03993305')) {
      api.createRecord('03993305', component.options)
    } else {
      api.reload('03993305', component.options)
    }
    module.hot.accept("./PraticheCaricate.vue?vue&type=template&id=03993305&", function () {
      api.rerender('03993305', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/PiattaformaRami/Estrazioni/PraticheCaricate.vue"
export default component.exports