var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-4 q-mr-sm" },
        [
          _c("q-input", {
            attrs: { outlined: "", mask: "##/##/####", label: "Dal giorno" },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function () {
                  return [
                    _c(
                      "q-icon",
                      {
                        staticClass: "cursor-pointer",
                        attrs: { name: "event" },
                      },
                      [
                        _c(
                          "q-popup-proxy",
                          {
                            ref: "qDateProxy",
                            attrs: {
                              "transition-show": "scale",
                              "transition-hide": "scale",
                            },
                          },
                          [
                            _c(
                              "q-date",
                              {
                                attrs: { mask: "DD/MM/YYYY" },
                                model: {
                                  value: _vm.dal_giorno,
                                  callback: function ($$v) {
                                    _vm.dal_giorno = $$v
                                  },
                                  expression: "dal_giorno",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "row items-center justify-end",
                                  },
                                  [
                                    _c("q-btn", {
                                      directives: [
                                        {
                                          name: "close-popup",
                                          rawName: "v-close-popup",
                                        },
                                      ],
                                      attrs: { label: "Close", flat: "" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.dal_giorno,
              callback: function ($$v) {
                _vm.dal_giorno = $$v
              },
              expression: "dal_giorno",
            },
          }),
          _c("br"),
          _c("q-input", {
            attrs: { outlined: "", mask: "##/##/####", label: "Al giorno" },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function () {
                  return [
                    _c(
                      "q-icon",
                      {
                        staticClass: "cursor-pointer",
                        attrs: { name: "event" },
                      },
                      [
                        _c(
                          "q-popup-proxy",
                          {
                            ref: "qDateProxy",
                            attrs: {
                              "transition-show": "scale",
                              "transition-hide": "scale",
                            },
                          },
                          [
                            _c(
                              "q-date",
                              {
                                attrs: { mask: "DD/MM/YYYY" },
                                model: {
                                  value: _vm.al_giorno,
                                  callback: function ($$v) {
                                    _vm.al_giorno = $$v
                                  },
                                  expression: "al_giorno",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "row items-center justify-end",
                                  },
                                  [
                                    _c("q-btn", {
                                      directives: [
                                        {
                                          name: "close-popup",
                                          rawName: "v-close-popup",
                                        },
                                      ],
                                      attrs: { label: "Close", flat: "" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.al_giorno,
              callback: function ($$v) {
                _vm.al_giorno = $$v
              },
              expression: "al_giorno",
            },
          }),
          _c("br"),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-4" },
        [
          _c("q-select", {
            attrs: {
              "emit-value": "",
              "map-options": "",
              outlined: "",
              options: _vm.options_stato,
              label: "Stato del Contratto",
            },
            model: {
              value: _vm.stato_pratica,
              callback: function ($$v) {
                _vm.stato_pratica = $$v
              },
              expression: "stato_pratica",
            },
          }),
          _c("br"),
          _c("q-select", {
            attrs: {
              "emit-value": "",
              "map-options": "",
              outlined: "",
              options: _vm.elenco_compagnie,
              label: "Compagnia",
            },
            model: {
              value: _vm.compagnia,
              callback: function ($$v) {
                _vm.compagnia = $$v
              },
              expression: "compagnia",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-12", attrs: { align: "center" } },
        [
          _c("br"),
          _c("q-btn", {
            attrs: { color: "primary", label: "Cerca" },
            nativeOn: {
              click: function ($event) {
                return _vm.onCercaNuovaProduzione.apply(null, arguments)
              },
            },
          }),
          _c("br"),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.is_loading,
              expression: "!is_loading",
            },
          ],
          staticClass:
            "text-center justify-center q-pa-md q-gutter-sm fixed-center",
          staticStyle: { "z-index": "1000" },
        },
        [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-12" },
        [
          _c("q-table", {
            attrs: {
              title: "",
              data: _vm.rows,
              columns: _vm.colonne,
              color: "primary",
              "row-key": "name",
              flat: "",
              "visible-columns": _vm.visibleColumns,
            },
            scopedSlots: _vm._u([
              {
                key: "top-right",
                fn: function () {
                  return [
                    _c("q-btn", {
                      attrs: {
                        color: "primary",
                        "icon-right": "archive",
                        label: "ESPORTA IN CSV",
                        "no-caps": "",
                      },
                      on: { click: _vm.esportaTabella },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._m(1),
      _c("div", { staticClass: "row col-12 col-md-12 justify-center" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "TORNA AL MENU",
                color: "blue-grey",
                icon: "undo",
                size: "md",
                icon_align: "left",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onIndietroClicked()
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("h4", { staticClass: "q-mb-sm" }, [_vm._v("Pratiche caricate")]),
      _vm._v(
        " Estrae tutti i contratti con data di caricamento compresa dal giorno al giorno selezionato "
      ),
      _c("br"),
      _c("br"),
      _c("hr"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-md-12" }, [_c("hr")])
  },
]
render._withStripped = true

export { render, staticRenderFns }